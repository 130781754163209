@lemon-session-calendar-list-calendar-cell-size: 2.5em; // experimental value, jsut fits nicely into current calendar
@lemon-session-calendar-list-calendar-selected-date-marker-size: 2px;

.lemon-sessionCalendar__week.lemon-sessionCalendar__week {
  // double class for more specific selector
  padding: @panel-padding / 2 0;

  * {
    color: @lemon-neutral-color-3;
  }
}

// ----- items
.lemon-sessionCalendar__itemHeaderDate {
  text-transform: uppercase;
}

.lemon-sessionCalendar__dateItem {
  padding: @panel-padding / 2 @panel-padding;
}

.lemon-sessionCalendar__item {
  padding: @panel-padding / 2 @panel-padding;
}

.lemon-sessionCalendar__itemRow {
  margin: @panel-padding 0;
}

.lemon-sessionCalendar__users {
}

// ----- dividers
.lemon-sessionCalendar__headerDivider {
  margin: 0 -@padding-md @padding-md / 2;
}

.lemon-sessionCalendar__itemDivider {
  margin: @padding-md / 2 0;
}

.lemon-sessionCalendar__emptyList {
  padding: 2 * @panel-padding @panel-padding;
  color: @lemon-text-color-secondary;
  text-align: center;
}

.lemon-sessionCalendar__syncButton {
  padding: @padding-xs;
}

.lemon-sessionCalendar__noSessionInfo {
  color: @lemon-text-color-secondary;
}

.lemon-sessionCalendar__calendar {
  position: sticky;
  top: (@panel-padding / 2);

  // hide default Ant calendar's navigation
  // Calendar's navigation cannot be removed by config so we used CSS sorcery
  .ant-fullcalendar-header {
    display: none;
  }

  .ant-fullcalendar {
    border: 0 none;
  }

  /* hide Month and Year buttons in calendar */
  .ant-fullcalendar-header {
    .ant-radio-group {
      display: none;
    }
  }

  .ant-fullcalendar th {
    .lemon-heading-4; // we cannot render this value so we'll inject header styling like this

    text-transform: uppercase;
  }

  .ant-fullcalendar td {
    text-align: center;
  }

  // ----- adjust display of selected/current/default calendar date
  .ant-fullcalendar-value {
    width: @lemon-session-calendar-list-calendar-cell-size;
    height: @lemon-session-calendar-list-calendar-cell-size;

    line-height: @lemon-session-calendar-list-calendar-cell-size;
    cursor: pointer; // look ma, we look like a link ;)

    // remove default styling
    background: none;
    box-shadow: none;
    border-radius: 0;

    // force tag to look like a circle - TODO: could this be a feature of tag?!
    .lemon-tag__tag {
      margin: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;

      line-height: @lemon-session-calendar-list-calendar-cell-size + @lemon-text-alignment-delta;
      cursor: pointer;
    }
  }

  .ant-fullcalendar-selected-day .ant-fullcalendar-value,
  .ant-fullcalendar-value:active {
    color: inherit;
  }

  // marker for selected date
  .ant-fullcalendar-selected-day .ant-fullcalendar-value::after {
    content: ' ';
    content: ' ';
    position: absolute;
    border: @lemon-session-calendar-list-calendar-selected-date-marker-size solid;
    border-radius: 50%;
    bottom: 6px;
    left: calc(50% - @lemon-session-calendar-list-calendar-selected-date-marker-size / 2);
  }
}
