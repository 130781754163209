.lemon-dashboard {
  .ant-card {
    margin: @padding-xs;
    border-radius: @lemon-border-radius;
  }

  .lemon-dashboard__card_activities,
  .lemon-dashboard__card_myUsers {
    .ant-row-flex {
      margin-bottom: 4px;
    }
  }
}
