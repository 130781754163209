/** Tabs used as main content layout */
.lemon-layoutTabs {
  .ant-tabs-bar {
    .panel;

    .ant-tabs-left-bar {
      padding-right: 0;
    }

    .ant-tabs-right-bar {
      padding-left: 0;
    }

    padding-right: 0;
    width: @lemon-layout-sider-width;

    .ant-tabs-tab {
      .lemon-heading-5;

      @media @md-screen-lt {
        text-align: center;
      }

      @media @xs-screen-lt {
        white-space: normal;
      }
    }

    @media @sm-screen-lt {
      width: 100%;
    }
  }

  .ant-tabs-content {
    .panel;

    margin-top: @panel-padding !important;
  }
}
