.lemon-statistics__statistics-header {
  justify-content: space-between;

  .ant-select {
    min-width: 250px;

    @media @md-screen-lt {
      min-width: unset;
      width: 85px;
    }
  }
}
