.lemon-changePassword__changePasswordForm,
.lemon-changePassword__changePasswordInfo {
  margin: 10% auto;
  min-width: 400px;
  padding: @padding-lg;
  width: 30%;

  .anticon {
    color: rgba(0, 0, 0, 0.25);
  }

  @media @md-screen-lt {
    min-width: @lemon-password-form-min-width-small;
    width: @lemon-password-form-width-small;
  }
}
