.lemon-instructionsComponent {
  text-align: center;
  padding: @padding-lg @padding-md;
  height: 50em;
}

.lemon-instructionsComponent__info {
  padding-bottom: @padding-md;
}

.lemon-instructionsComponent__video {
  width: 90%;
  height: 80%;
}

@media @md-screen-lt {
  .lemon-instructionsComponent {
    height: 40em;
  }
}
