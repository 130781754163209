// ----- Custom Ant variables
// ------------------------------

// ----- lemon colors scheme

@lemon-primary-color-3: #0a1823;
@lemon-secondary-color-3: #e7e5ff;

@lemon-primary-color-2: #008682;
@lemon-secondary-color-2: #00ccc5;

@lemon-primary-color-1: #eea00b;
@lemon-secondary-color-1: #f9cb77;

@lemon-primary-color-4: #f5d547;
@lemon-secondary-color-4: #fcf2c5;

@lemon-primary-color-5: #163750;
@lemon-secondary-color-5: #1a4260;

@lemon-neutral-color-1: #000;
@lemon-neutral-color-2: #505050;
@lemon-neutral-color-3: #ccc;
@lemon-neutral-color-4: #ddd;
@lemon-neutral-color-5: #ededed;
@lemon-neutral-color-6: #f7f7f7;

@lemon-warning-color: #d83a3a;

// ----- colors

@primary-color: @lemon-primary-color-1;

@background-color-light: @lemon-neutral-color-6; // background of header and selected item
@background-color-base: @lemon-neutral-color-5; // Default grey background color

// ----- fonts&text

@font-family: 'Source Sans Pro', /* following are antd's default fonts */ -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

@font-size-base: 14px;
@font-size-lg: @font-size-base * 1.2;
@font-size-sm: 12px;

@line-height-base: 1.5;

// ----- headings

@heading-1-size: 30px;
@heading-2-size: 24px;
@heading-3-size: 18px;
@heading-4-size: 16px;
@heading-5-size: 14px;

@heading-color: @lemon-primary-color-2;

.lemon-typography-title(@fontSize, @lineHeight, @fontWeight) {
  .typography-title(@fontSize, @fontWeight, @lineHeight, @heading-color, 0);

  font-weight: @fontWeight;
  // kill top/bottom margins because it's more in the way than usefull, this is not pure text document, it's an app
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.typography-title-1() {
  .lemon-typography-title(@heading-1-size, 40px, 500);
}
.typography-title-2() {
  .lemon-typography-title(@heading-2-size, 34px, 600);
}
.typography-title-3() {
  .lemon-typography-title(@heading-3-size, 26px, 400);
}
.typography-title-4() {
  .lemon-typography-title(@heading-4-size, 24px, 400);
}
.typography-title-5() {
  .lemon-typography-title(@heading-5-size, 24px, 400);
}

// Disabled states
@disabled-color: @lemon-neutral-color-4;
@disabled-bg: @background-color-base;
@disabled-color-dark: @lemon-neutral-color-3;

// ----- buttons

@btn-background-default: #fff;

// ----- borders

// Border color
@border-color-base: @lemon-secondary-color-1; // base border outline a component
@border-color-split: @lemon-secondary-color-1; // split border inside a component

// ----- form

@label-color: @lemon-neutral-color-3;

// ----- rate

@rate-star-color: @lemon-primary-color-3;
@rate-star-bg: @lemon-secondary-color-4;
