.lemon-repository__breadcrumb {
  padding: @padding-lg @padding-md @padding-md;

  .lemon-icon {
    vertical-align: baseline;
    color: @primary-color;
  }

  .ant-breadcrumb a,
  .ant-breadcrumb-separator {
    color: @lemon-primary-color-2;
  }

  .ant-breadcrumb a:hover {
    color: @lemon-primary-color-3;
    text-decoration: underline;
  }
}

.lemon-repository__content {
  .ant-card-head {
    border-bottom-width: 2px;
  }

  .ant-card-body {
    padding: 0;
  }

  .ant-btn {
    text-transform: uppercase;

    &:not(:hover) {
      background-color: inherit;
    }
  }

  &.items-selected .ant-table-thead {
    background-color: @lemon-primary-color-5;
  }
}

.lemon-repository__menuItem_newFolder {
  a {
    color: @lemon-primary-color-2;
  }
}

.lemon-repository__menuItem_newFile {
  span {
    color: @lemon-primary-color-3;
  }
}

/* .lemon-repository__header {
} */

.lemon-repository__dropDownIcon {
  font-size: @icon-size-xl;
  float: right;
}

.lemon-repository__previewImageTooltip {
  height: 20vh;

  &:not(.ant-tooltip-hidden) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-tooltip-inner {
    background-color: @tooltip-background-color;
  }

  .ant-tooltip-arrow {
    border-right-color: @tooltip-background-color;
  }

  img {
    max-width: 100%;
    max-height: 20vh;
  }
}

/* .lemon-repository__sessionAddModal {
} */

.lemon-repository__folderSelectModal {
  .ant-modal-footer {
    text-align: left;
    padding-bottom: @padding-lg;
  }
}

.lemon-repository__folderListTable {
  margin: @padding-xs;
  border-radius: @lemon-border-radius;
  background-color: @panel-light-bg;

  .ant-checkbox-wrapper {
    display: none;
  }
}
//
.lemon-repository__fileListTable {
  // margin: @padding-xs;
  border-radius: @lemon-border-radius;
  background-color: @panel-light-bg;

  table {
    width: -moz-fit-content !important;
  }

  .ant-table-thead {
    tr {
      background: none;

      th {
        background-color: inherit;
        color: inherit;
        font-weight: bold;
      }
    }
  }

  .filtered-icon {
    color: #1890ff !important;
  }

  .fa-folder {
    color: @lemon-primary-color-2;
  }

  .fa-file {
    color: @lemon-primary-color-3;
  }

  td > a {
    color: inherit;
    font-weight: bold;
  }

  .ant-table-tbody > tr.ant-table-row-selected td {
    background-color: @lemon-secondary-color-5;
  }

  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background-color: @lemon-neutral-color-6;
  }
}

.lemon-repository__previewImageModal {
  width: auto !important;

  .ant-modal-close {
    color: white;
    position: fixed;
    right: 1vw;
    top: 0;
  }

  .ant-modal-content {
    top: 5vh;
  }

  .ant-modal-header {
    background: rgba(0, 0, 0, 0.6);
    border-bottom: 1px solid #000;
    position: fixed;
    text-align: left;
    left: 0;
    top: 0;
    width: 100vw;
  }

  .ant-modal-title {
    color: white;
    position: relative;
    left: 1vw;

    a {
      color: inherit;
    }
  }

  img {
    max-width: 90vw;
    max-height: 85vh;
  }
}

.lemon-repository__dropzone {
  position: relative;
}

.lemon-repository__dropzoneMask {
  display: none;
  position: absolute;
  border: 2px dashed @primary-color;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100; // TODO: z-index from variables

  background-color: fadeout(@primary-color, 80%);
  text-align: center;

  // show mask on active zone
  .lemon-repository__dropzone--active & {
    display: block;
  }

  // drop zone info message
  .lemon-repository__dropzoneMessage {
    // position in the middle of window (hopefuly we won't exit outside of repository area)
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);

    display: inline-block; // don't stretch through entire row
    padding: @padding-lg;

    border-radius: @lemon-border-radius;

    background-color: fadeout(@primary-color, 40%);

    &,
    .lemon-icon {
      .typography-title-1(); // fonts like H1
      color: @btn-background-default;
    }
  }
}

.lemon-fileRepository__tagSelect {
  width: 60%;
}

.lemon-fileRepository__tagAddInput_select {
  width: 7em;
}

.lemon-fileRepository__tagAddInput_button {
  cursor: pointer;
}

.lemon-fileRepository__fileLink_openFolder_public {
  margin-right: 5px;
}
