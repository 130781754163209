.lemon-tutoringRoom__sessionInfoContainer {
  position: absolute;
  top: @panel-padding;
  left: @panel-padding;
  margin: 0; // kill panel's default margin
  width: calc(@lemon-layout-content-width - 2 * @panel-padding);
  z-index: @stack-zindex-panel;

  .lemon-tutoringRoom__sessionInfoView {
    flex: 100; // make sure this takes all available space
  }

  .lemon-tutoringRoom__otherParticipants {
    display: flex;
    align-items: center;
    padding-left: @padding-md;
  }
}

.lemon-tutoringRoom__sessionTimerContainer {
  // color-6 with white background in design
  // color-4 implemented for better contrast, because of gray background
  background-color: @lemon-neutral-color-4;
  padding: @padding-xxs;
}

.lemon-tutoringRoom__sessionTimer {
  background-color: @lemon-primary-color-4;
  // width: 44%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;

  &--nearEnd {
    background-color: @lemon-warning-color;
  }
}
