@sessionRejection-participantAvatarOffset: @avatar-size + 4 * @panel-padding;

.lemon-sessionParticipate__container {
}

.lemon-sessionParticipate__header {
  font-size: @lemon-font-size-lg;
  color: @lemon-heading-color;
  text-align: center;
}

.lemon-sessionParticipate__content {
  margin-bottom: @panel-padding;
}

.lemon-sessionParticipate__sessionStartDateContainer {
  border-bottom: @panel-border;
}

.lemon-sessionParticipate__sessionStartDate {
  display: inline-block;
  width: 8em;
  white-space: nowrap;
  padding: @panel-padding @panel-padding 0;
  font-size: @lemon-font-size-lg;
  font-weight: bold;
  color: @lemon-heading-color;
  text-align: center;
}

.lemon-sessionParticipate__sessionStartTime {
  display: inline-block;
  width: 8em;
  padding: @panel-padding / 2 @panel-padding 0;
  font-size: @lemon-font-size-lg;
  white-space: nowrap;
  text-align: center;
}

.lemon-sessionParticipate__user {
  .lemon-sessionParticipate__sessionStartTime;
}

.lemon-sessionParticipate__sessionStartTimeContainer {
  min-height: @avatar-size; // avatar is absolutely positioned, so keep at at least it's height
  padding-right: @sessionRejection-participantAvatarOffset;
}

.lemon-sessionParticipate__participant {
  position: absolute;
  top: 0;
  right: @panel-padding;
}

.lemon-sessionParticipate__footer {
  padding: @panel-padding;
}

.lemon-sessionParticipate__sessionParticipantName {
  display: inline-block;
  margin-left: 2 * @panel-padding;
}
