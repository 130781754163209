.lemon-navMenu__container {
  // better child element arrangement
  display: flex;

  @media @xl-screen-gte {
    width: @lemon-screen-xl-min;
    margin: 0 auto;
  }
}

.lemon-navMenu__mobileMenuIcon {
  display: none;
  margin: auto;
  border: @panel-lighter-border;
  padding: @padding-xs;
  border-radius: @lemon-border-radius;
  z-index: @stack-zindex-element-1;

  @media @md-screen-lt {
    display: block;
  }
}
