#root {
  height: 100%;

  > [data-reactroot] {
    height: 100%;
  }
}

// ----------

.lemon-app__container {
  width: 100%;
  height: 100%;
}

// ----- Application Standard Layout

.lemon-appLayout__layout {
  min-height: 100%;
}

.lemon-appLayout__header {
  background-color: @layout-header-bg-color;
  padding: 0;

  .lemon-appLayout__headerContent {
    @media @xl-screen-gte {
      width: @lemon-screen-xl-min;
      margin: 0 auto;
    }
  }

  @media @lg-screen-lt {
    padding: 0 @padding-xs;
  }
}

.lemon-appLayout__content {
  flex-direction: column;
  background-color: @app-content-bg;

  @media @xl-screen-gte {
    width: @lemon-screen-xl-min;
    margin: 0 auto;
  }
}

.lemon-appLayout__footer {
  align-self: flex-end;
  width: 100%;
  text-align: center;

  @media @md-screen-lt {
    padding: 2 * @padding-xs @padding-xs;
  }
}

// ----- Application full page layout

.lemon-fullPageLayout__layout {
  height: 100%;
}

.lemon-fullPageLayout__content {
  flex-direction: column;
}

// ----- Layout Utils

.lemon-layout__internalLayout {
  height: 100%;
}
