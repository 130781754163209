.lemon-sessionCreate__modalForm {
  .ant-steps {
    padding-bottom: @padding-lg;
  }

  .ant-calendar-picker,
  .ant-time-picker {
    width: 100%;
  }
}

.lemon-sessionCreate__instructorInfo {
  padding-right: @padding-lg;

  span:first-child {
    font-weight: normal;
    padding-right: @padding-sm;
  }
}
