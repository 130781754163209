.lemon-notificationInfoBar__itemContainer {
  .panel; // inherit from panel

  .notification__text {
    text-align: center;
  }

  background-color: @lemon-primary-color-5;

  .lemon-icon {
    color: @lemon-primary-color-1;
  }

  a {
    text-decoration: underline;
  }
}

.lemon-notificationInfoBar__timer {
  color: @lemon-primary-color-1;
  font-weight: bold;
}

.lemon-notificationInfoBar__participantName {
  font-weight: bold;
}

.lemon-notificationInfoBar__tooltip {
  padding: @panel-padding;
}
