
.lemon-calendarNavigation__container {
  margin: -@panel-padding -@panel-padding 0;
  padding: @panel-padding/2;
  border-bottom: @panel-border;
}

.lemon-calendarNavigation__todayButton { 
}
.lemon-calendarNavigation__nextButton {
}
.lemon-calendarNavigation__previousButton {
}

.lemon-calendarNavigation__currentMonthContainer {
  flex: 100; // take all available flex space
  text-align: center;

  .lemon-calendarNavigation__currentMonth {
    color: @lemon-primary-color-1;
    text-transform: capitalize;
  }
}


.lemon-calendarNavigation__modeButton {
}
