
.lemon-notificationMenu {
  > .ant-menu {
    margin-top: @lemon-layout-header-nevigation-item-border-width;
    line-height: @lemon-layout-header-height - @lemon-layout-header-nevigation-item-border-width;
    background-color: transparent;
    border: 0 none;
    color: @layout-header-text-color;

    // kill bottom menu border which is replaced with top margin
    > .ant-menu-submenu {
      border: 0 none;
    }
  }


  .lemon-notificationMenu__mainIcon {
    font-size: @icon-size-xxl;

    ~ .ant-badge .ant-badge-status-dot {
      margin-right: 24px;
      margin-top: 18px;
      width: 10px;
      height: 10px;
      background: @lemon-primary-color-5 !important; // override badge  inline color
      border: 1.5px solid @lemon-primary-color-1;
    }
  }
}

// submenu ID starts with given key, this is very ugly but I couldn't selec submenu any other way
[id^="lemon-notificationMenu__submenu"] {
  // TODO: define notification menu width/height using media query steps, not only onne fixed value
  max-width: 400px;
  max-height: 500px;
  overflow: auto;

  .ant-menu-item {
    height: auto;
    white-space: normal;
  }
}

.lemon-notificationMenu__notification {
  padding-left: @padding-lg;
  padding-right: @padding-lg;

  &--clickable {
    cursor: pointer;
  }

  .lemon-notificationMenu__notificationIcon {
    float: left;
    margin-left: -@padding-lg;
    line-height: 2*@padding-md;
  }

  .lemon-notificationMenu__closeButton {
    float: right;
    margin-right: -@padding-lg;
    line-height: 2*@padding-md;
  }
}

.lemon-notificationMenu__notificationTitle {
  font-weight: bold;
  white-space: nowrap;
  line-height: 2*@padding-md;
}

.lemon-notificationMenu__notificationInfo {
  color: inherit;
  line-height: @padding-lg;
}

.lemon-notificationMenu__notificationContent {
  color: inherit;
  line-height: @padding-lg;
  opacity: 0.75;
}

.lemon-notificationMenu__sentDateTime {
  text-align: right;
  font-size: 90%;
  opacity: .5;
  line-height: @padding-lg;
}
