.lemon-userFeedback__progressStatusContainer {
  display: inline-block;

  &--global {
    position: fixed;
    top: 40%;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: @stack-zindex-progress;
  }
}

.lemon-userFeedback__progressStatusMask {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.45);
  height: 100%;
  filter: alpha(opacity=45);
}
