
// temporary timeline colors - replace with real ones when proper design comes
@lemon-timeline-item-system-background: @lemon-secondary-color-1;
@lemon-timeline-item-current-background: @lemon-primary-color-3;
@lemon-timeline-item-participant-background: @lemon-primary-color-2;
@lemon-timeline-system-font-color: @lemon-primary-color-1;
@lemon-timeline-divider-font-color: @lemon-primary-color-1;
@lemon-timeline-message-font-color: @lemon-primary-color-2;


// outer container for keeping cotainer's h/w
.lemon-timeline__container {
  position: relative;
  height: 100%;
  min-height: 150px;

  &.panel {
    // remove panel's margins
    margin: 0;
  }

  background-color: @panel-light-bg;
}

// internal flex container for internal element layout
.lemon-timeline__internalContainer {
  // positioned absolutely to prevent internal content from stretching containers, content should scroll inside available space
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // use flex for internal layout and makes children distribute according to available space in container
  display: flex;
  flex-direction: column;
}

//
.lemon-timeline__listContainer {
  overflow-y: auto; // makes list scroll if larger then available space
  flex: 100; // make list container take most of space and push message send form to the bottom
  padding: @panel-padding;

  .lemon-icon {
    vertical-align: 0; // allow better vertical alignment
  }
}

// date divider - custom colors
.lemon-timeline__dateDivider {
  color: @lemon-timeline-divider-font-color;

  .lemon-listDivider__strike {
    border-color: @lemon-timeline-divider-font-color;
  }
}

.lemon-timeline__icon {
}

// all items common styles
.lemon-timeline__systemItem,
.lemon-timeline__messageItem {
  padding: @panel-padding;

  .lemon-timeline__messageParticipant {
  }
}

// system items
.lemon-timeline__systemItem {
  .lemon-timeline__systemItemHeader {
    padding-bottom: @padding-xxs;
    font-size: @lemon-font-size-lg;
    color: @lemon-timeline-system-font-color;
  }

  .lemon-timeline__systemItemContent {
    padding: @panel-padding;
    border-radius: @panel-border-radius;
    color: @lemon-timeline-system-font-color;
    word-wrap: break-word;

    background-color: @lemon-timeline-item-system-background;
  }
}

// message items
.lemon-timeline__messageItem {
  .lemon-timeline__messageItemHeader {
    padding-bottom: @padding-xxs;
    font-size: @lemon-font-size-lg;
    color: @lemon-timeline-message-font-color;
  }

  .lemon-timeline__messageItemContent {
    &.lemon-timeline__item--currentUser {
      .lemon-createBubble(@lemon-timeline-item-current-background, 'left');
    }

    &.lemon-timeline__item--otherParticipant {
      .lemon-createBubble(@lemon-timeline-item-participant-background, 'right');
    }
  }
}

.lemon-timeline__affixOverride {
  .ant-affix {
    position: absolute !important;
    top: 0 !important;
  }
}


