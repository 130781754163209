
.avatar,
.lemon-image__avatar {
  display: inline-block;
  height: @avatar-size;
  width: @avatar-size;
  border: @avatar-border;
  border-radius: @avatar-border-radius;
  background: @avatar-bg;

  > .anticon {
    // center align icon's SVG element
    display: flex;
    margin: 0;
    align-items: center;
    justify-content: center;

    font-size: @avatar-size * 0.6; // a bit less than avatar
    height: 100%;
    color: @avatar-icon-color;
  }

  &--sm {
    height: @avatar-size-sm;
    width: @avatar-size-sm;

    > .anticon {
      font-size: @avatar-size-sm * 0.6; // a bit less than avatar
    }
  }

  &--lg {
    height: @avatar-size-lg;
    width: @avatar-size-lg;

    > .anticon {
      font-size: @avatar-size-lg * 0.6; // a bit less than avatar
    }
  }

  &--xl {
    height: @avatar-size-xl;
    width: @avatar-size-xl;

    > .anticon {
      font-size: @avatar-size-xl * 0.6; // a bit less than avatar
    }
  }
}

.lemon-image__thumbnail {
  height: @thumbnail-size;
  width: 100%;
  object-fit: cover;

  > .anticon {
    // center align icon's SVG element
    display: flex;
    margin: 0;
    align-items: center;
    justify-content: center;
    font-size: @thumbnail-size * 0.9; // a bit less than entire thumbnail
  }

  &--sm {
    height: @thumbnail-size-sm;

    > .anticon {
      font-size: @thumbnail-size-sm * 0.9; // a bit less than entire thumbnail
    }
  }

  &--lg {
    height: @thumbnail-size-lg;

    > .anticon {
      font-size: @thumbnail-size-lg * 0.9; // a bit less than entire thumbnail
    }
  }

  &--xl {
    height: @thumbnail-size-xl;

    > .anticon {
      font-size: @thumbnail-size-xl * 0.9; // a bit less than entire thumbnail
    }
  }
}
