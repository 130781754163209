
.lemon-tutoringRoom__sessionFileList {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.lemon-tutoringRoom__sessionFileListItem {
  display: flex;
  line-height: @icon-size-xl;
  padding: (@panel-padding / 4);

  &--noAddButton {
    margin-left: @icon-size-xl + @icon-size-lg / 2; // compensate for missing button
  }

  .lemon-tutoringRoom__sessionFileLink {
    color: inherit;
  }

  .lemon-tutoringRoom__sessionFileAddButton {
    font-size: @icon-size-xl;
    margin-right: @icon-size-lg / 2;
  }
}
