
.lemon-listDivider__container {
  position: relative;
  text-align: center;
  line-height: @divider-line-height;
  background-color: @divider-label-bg;

  .lemon-listDivider__strike {
    border: 0 none;
    border-top: 1px solid @divider-line-color;
    margin-top: -@divider-label-font-size;
  }
  
  .lemon-listDivider__label {
    z-index: @divider-zindex;

    .lemon-listDivider__labelContent {
      padding: 0 @divider-padding;
      border-radius: @lemon-border-radius;
      background-color: @divider-label-bg;
      font-size: @divider-label-font-size;
    }
  }
}
