
.lemon-tutor__tutorListContainer {
  background-color: @app-content-bg;
  padding: @panel-padding;
}

.lemon-tutor__tutorListFilter {
  .ant-input-number {
    width: 100%;
  }

  .ant-checkbox-wrapper > span {
    text-transform: capitalize;
  }
}

.lemon-tutor__tutorList {
  .ant-list-header {
    label {
      color: @primary-color;
      font-weight: bold;
    }
  }

  a {
    color: inherit;
    width: 100%;
  }

  .ant-list-footer {
    text-align: right;
  }
}

@tutorListAvatarOffset: @avatar-size-lg + @padding-md;

.lemon-tutorList__avatarContainer {
  padding-left: @tutorListAvatarOffset;

  .lemon-image__avatar {
    position: absolute;
    margin-left: -(@tutorListAvatarOffset);
  }
}

.lemon-tutorList__educationAreas {
  padding-top: @padding-md;
}
