.lemon-userProfile__container {
  .lemon-userProfile__footer {
    border-top: @panel-border;
    padding-top: @panel-padding;
  }
}

.lemon-userProfile__avatarContainer {
  text-align: center;
}

.lemon-userProfile__contact-data {
  ul.addresses,
  ul.contact-data {
    border-left: 2px solid @disabled-color-dark;
    list-style-type: none;
    padding-left: 12px;
  }

  .user-profile__tabs;
}

.lemon-userProfile__calendar {
  .user-profile__tabs;
}

.lemon-userProfile__details {
  ul.experience {
    border-left: 2px solid @disabled-color-dark;
    list-style-type: none;
    padding-left: @padding-md;
  }

  .user-profile__tabs;
}

.user-profile__link-publicProfile {
  padding: @padding-md 0;

  @media @md-screen-lt {
    .text-center;
  }
}

.lemon-userProfile__row {
  @media @md-screen-lt {
    justify-content: center;
  }
}

.user-profile__tabs {
  @media @md-screen-lt {
    text-align: center;
  }
}

.lemon-userProfile__tooltip {
  margin: 0;
}
