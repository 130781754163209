.lemon-tutoringRoom__layout {
}

.lemon-tutoringRoom__layoutContent {
  flex-direction: row;

  & .aww {
    height: auto !important; // awwapp sets inline w/h to 100% which makes canvas have 0 height on safari/chrome on mac/ios
  }
}

.lemon-tutoringRoom__sidebarContainer {
  // use flex for internal layout and makes children distribute according to available space in container
  display: flex;
  flex-direction: column;
  // positioned absolutely to prevent internal content from stretching containers, content should scroll inside available space
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: @panel-padding;
  width: calc(@lemon-layout-sider-width - @panel-padding);

  box-shadow: @lemon-shadow-light;

  .lemon-tutoringRoom__layout--fullscreen & {
    position: static;
  }

  // whiteroom has minimal sider
  .lemon-tutoringRoom__whiteroomContainer & {
    bottom: auto;
  }
}

.lemon-tutoringRoom__closeButtonContainer {
  margin-bottom: @panel-padding / 2; // make it half height to fit two rows, for now

  .lemon-tutoringRoom__whiteroomContainer & {
    margin-bottom: 0;
  }
}

.lemon-tutoringRoom__createButtonContainer {
  margin-bottom: @panel-padding;
}

.lemon-tutoringRoom__timelineContainer {
  margin-top: @panel-padding;
  flex: 10; // take entire remaining space lef over from other views

  .lemon-tutoringRoom__sidebarContainer & {
    margin: -1px 0 0; // stick timeline container to container above (video conitaner) so it looks like its going under it
  }
}

.lemon-tutoringRoom__sessionFilesContainer {
  .lemon-tutoringRoom__sessionFileList {
    margin-top: @panel-padding; // add some spacing between panels
  }
}

.lemon-tutoringRoom__accessibilityKeyboard {
  position: absolute;
  z-index: 11;
  right: 0;
  bottom: 0;
  height: 300px;
  width: 600px;
}

.lemon-tutoringRoom__keyboardToggleButton {
  margin: 5px 0;
  height: fit-content;
  width: fit-content;
  position: absolute;
  left: 3px;
  bottom: 3px;
  z-index: 10;
}

.lemon-tutoringRoom__keyboardButton1 {
  width: calc(500px / 10);
  margin: 2.5px;
  height: calc(280px / 5);
  font-size: 20px;
  font-weight: bold;
}

.lemon-tutoringRoom__keyboardButton2 {
  width: calc(525px / 5); // double the width of button1
  height: calc(280px / 5);
  margin: 2.5px;
  font-size: 20px;
  font-weight: bold;
}

.lemon-tutoringRoom__keyboardButton3 {
  width: calc(537.5px / 2.5); // double the width of button1
  height: calc(280px / 5);
  margin: 2.5px;
  font-size: 20px;
  font-weight: bold;
}

.lemon-tutoringRoom__enterButton {
  width: calc(525px / 5); // double the width of button1
  height: calc(297.5px / (5 / 3));
  margin: 2.5px;
  font-size: 20px;
  font-weight: bold;
}

.lemon-tutoringRoom__keyboardIcon {
  height: 50px;
  width: 80px;
  margin: 0;
  padding: 0;
}

.alignBaseline {
  vertical-align: baseline;
}
