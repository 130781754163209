.lemon-modal__modal {

  max-width: 90%; // keep modals somewhat responsive
  .ant-modal-header {
    border-bottom: none;
  }
  .ant-modal-footer {
    border-top: none;
  }
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-title {
    text-transform: uppercase;
    color: @lemon-neutral-color-2;
    font-weight: bold;
  }
  .ant-modal-close {
    color: @primary-color;
  }

  &--sm {
    width: @lemon-modal-width-sm !important; // make sure we override inline width set by modal
  }

  &--md {
    width: @lemon-modal-width-md !important; // make sure we override inline width set by modal
  }

  &--lg {
    width: @lemon-modal-width-lg !important; // make sure we override inline width set by modal
  }

}
