@sessionRejection-participantAvatarOffset: @avatar-size + 4 * @panel-padding;

.lemon-sessionRejection__container {
}

.lemon-sessionRejection__header {
  font-size: @lemon-font-size-lg;
  color: @lemon-heading-color;
  text-align: center;
}

.lemon-sessionRejection__content {
  margin-bottom: @panel-padding;
}

.lemon-sessionRejection__sessionStartDateContainer {
  border-bottom: @panel-border;
}

.lemon-sessionRejection__sessionStartDate {
  display: inline-block;
  width: 8em;
  white-space: nowrap;
  padding: @panel-padding @panel-padding 0;
  font-size: @lemon-font-size-lg;
  font-weight: bold;
  color: @lemon-heading-color;
  text-align: center;
}

.lemon-sessionRejection__sessionStartTime {
  display: inline-block;
  width: 8em;
  padding: @panel-padding / 2 @panel-padding 0;
  font-size: @lemon-font-size-lg;
  white-space: nowrap;
  text-align: center;
}

.lemon-sessionRejection__sessionStartTimeContainer {
  min-height: @avatar-size; // avatar is absolutely positioned, so keep at at least it's height
  padding-right: @sessionRejection-participantAvatarOffset;
}

.lemon-sessionRejection__participant {
  position: absolute;
  top: 0;
  right: @panel-padding;
}

.lemon-sessionRejection__footer {
  padding: @panel-padding;
}

.lemon-sessionRejection__sessionParticipantName {
  display: inline-block;
  margin-left: 2 * @panel-padding;
}
