// ----- Icons

.lemon-icon {
  font-size: @icon-size;

  &--sm {
    font-size: @icon-size-sm;
  }

  &--lg {
    font-size: @icon-size-lg;
  }

  &--xl {
    font-size: @icon-size-xl;
  }

  &--xxl {
    font-size: @icon-size-xxl;
  }
}
