.lemon-application__headerAppInfo {
  display: flex;
  font-size: 1.2em;
  color: @layout-header-text-color;
  white-space: nowrap; // prevent items from

  > .ant-menu {
    width: 100%;
    line-height: @lemon-layout-header-height - @lemon-layout-header-nevigation-item-border-width;
    background-color: transparent;
    border: 0 none;
    color: @layout-header-text-color;

    @media @md-screen-lt {
      display: none;
    }

    .ant-menu-item {
      border-bottom: @lemon-layout-header-nevigation-item-border-width solid transparent;

      a {
        color: @layout-header-text-color;
      }

      &:hover,
      &.ant-menu-item-selected {
        border-color: @lemon-layout-header-nevigation-item-border-color;
      }
    }
  }
}

.lemon-application__headerAppLogo {
  height: 40px;
}
