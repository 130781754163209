// ----- Manual Antd CSS overrides
// ----------------------------------------

// ---------- Core

// ----- headings

h1&,
h1,
.lemon-heading-1 {
  .typography-title-1();

  font-family: 'Poppins';
}

h2&,
h2,
.lemon-heading-2 {
  .typography-title-2();

  font-family: 'Source Sans Pro';
}

h3&,
h3,
.lemon-heading-3 {
  .typography-title-3();

  font-family: 'Source Sans Pro';
}

h4&,
h4,
.lemon-heading-4 {
  .typography-title-4();

  font-family: 'Source Sans Pro';
}

// H5 - missing in ant
h5&,
h5,
.lemon-heading-5 {
  .typography-title-5();

  font-family: 'Poppins';
}

// ---------- Layout overrides

.ant-layout {
  background: none;
}

.ant-layout-header {
  background: none;
  height: auto;
}

.ant-layout-footer {
  height: auto;
}

.ant-list-vertical .ant-list-item-meta-avatar {
  display: inline;
}

.ant-layout-content {
  display: flex;
  // overflow: auto;

  > * {
    width: 100%;
  }
}

.ant-form-inline .ant-form-item > .ant-form-item-control-wrapper {
  display: inherit;
}

// ---------- File upload overrides

.ant-upload.ant-upload-select {
  display: inline;
}

.upload-list .ant-upload-list-item {
  float: left;
  width: 90%;
  margin-right: 8px;
}

.upload-list .ant-upload-animate-enter {
  animation-name: uploadAnimateInlineIn;
}

.upload-list .ant-upload-animate-leave {
  animation-name: uploadAnimateInlineOut;
}

// ---------- Menu overrides

.ant-menu-submenu-title {
  &,
  &:hover {
    color: #fff;
  }
}

.ant-menu-submenu-popup::before {
  display: none; // for some reason scrollbar in menu does not work because of this pseudoelement
}

/*
.ant-menu-horizontal > .ant-menu-submenu:hover {
  border-bottom: 2px solid blue;
  color: #1890ff;
}
*/

// ---------- Buttons

.@{btn-prefix-cls} {
  border-color: @lemon-primary-color-1;
  color: @lemon-primary-color-1;
  background-color: @btn-background-default;

  box-shadow: none;
  text-shadow: none;

  &:hover {
    background-color: @lemon-secondary-color-4;
    color: @lemon-primary-color-1;
  }

  &:active {
    border-color: @lemon-primary-color-4;
    background-color: @lemon-primary-color-4;
    color: @btn-background-default;
  }

  &:disabled {
    border-color: @disabled-color;
    background-color: @btn-background-default;
    color: @disabled-color;
  }

  &&-primary {
    color: @btn-background-default;
    background-color: @lemon-primary-color-1;

    &:hover {
      border-color: @lemon-secondary-color-4;
      background-color: @lemon-secondary-color-4;
      color: @lemon-primary-color-1;
    }

    &:active {
      border-color: @lemon-primary-color-4;
      background-color: @lemon-primary-color-4;
      color: @btn-background-default;
    }

    &:disabled {
      border-color: @disabled-bg;
      background-color: @disabled-bg;
      color: @disabled-color-dark;
    }
  }

  &&-link {
    &,
    &:hover,
    &:active,
    &:disabled {
      background-color: transparent;
      border-color: transparent;
    }

    &:active {
      color: @lemon-secondary-color-1; // keep text color because this btn doesn't have bg
    }
  }

  &&-danger {
    border-color: @lemon-warning-color;
    background-color: @lemon-warning-color;
    color: @btn-background-default;
  }
}

// ----- Modal

.@{dialog-prefix-cls} {
  &-content {
    padding: 0 40px;
  }

  &-header {
    padding: 20px 0;
    min-height: 80px;
    display: flex;
    align-items: center;

    // stretch children
    > * {
      width: 100%;
    }
  }

  &-footer {
    padding: 20px 0;
    min-height: 100px;
    display: flex;
    align-items: center;

    // stretch children
    > * {
      width: 100%;
    }
  }
}

// ---------- Tabs

.@{tab-prefix-cls} {
  .@{tab-prefix-cls}-ink-bar {
    height: 4px; // thicker active tab marker
  }

  .@{tab-prefix-cls}-left-bar,
  .@{tab-prefix-cls}-right-bar {
    .@{tab-prefix-cls}-ink-bar {
      width: 4px;
    }
  }

  .@{tab-prefix-cls}-nav {
    .@{tab-prefix-cls}-tab {
      &:hover:not(.@{tab-prefix-cls}-tab-disabled) {
        background-color: @lemon-neutral-color-6;
      }
    }
  }
}

// ---------- Form

.@{form-prefix-cls}-item-label > label {
  .lemon-heading-5;
  color: @label-color; // repeat color to override heading's color
  text-transform: uppercase;
}

// ---------- Cursor

.ant-upload {
  .ant-upload-disabled {
    cursor: default;
  }
}
