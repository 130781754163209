
.lemon-sessionTutorFeedbackEditor__container {
  .ant-form-item {
    margin: 0; // kill default margin, too much space
  }
}

.lemon-sessionTutorFeedbackEditor__view {
  min-height: 3em;
  max-height: 10em;
  padding: @panel-padding/2 @panel-padding;
  overflow-y: auto; // handle large text

  white-space: pre-wrap;
  line-height: 1.5;
}