.lemon-registration__registrationForm,
.lemon-registration__registrationInfo,
.lemon-activation__activationInfo {
  margin: 10% auto;
  min-width: 600px;
  padding: @padding-lg;
  width: 50%;

  .ant-steps {
    padding-bottom: @padding-lg;
  }

  .ant-radio-group {
    width: 100%;
  }

  .anticon {
    color: rgba(0, 0, 0, 0.25);
  }

  @media @md-screen-lt {
    min-width: @lemon-password-form-min-width-small;
    width: @lemon-password-form-width-small;
  }
}

.lemon-registration__userRoleImage {
  height: 100px;
  width: 100px;
}
