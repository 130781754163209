.lemon-userMenu {
  > .ant-menu {
    width: 100%;
    margin-top: @lemon-layout-header-nevigation-item-border-width;
    line-height: @lemon-layout-header-height - @lemon-layout-header-nevigation-item-border-width;
    background-color: transparent;
    border: 0 none;
    color: @layout-header-text-color;

    // kill bottom menu border which is replaced with top margin
    > .ant-menu-submenu {
      border: 0 none;
    }
  }

  // make user menu items full width
  .ant-menu-horizontal > .ant-menu-item,
  .ant-menu-horizontal > .ant-menu-submenu {
    width: 100%;

    // reduce padding to save space
    .ant-menu-submenu-title {
      padding-right: 0;
    }
  }

  // don't know what this element is for but has no meaningfull content and causes overflow scroll of entire page
  .ant-menu-overflowed-submenu {
    display: none;
  }

  // make user menu title items vertically aligned
  .lemon-userMenu__menuContainer {
    display: flex;
    align-items: center;

    > .lemon-userMenu__userName {
      // make room for content
      @media @md-screen-lt {
        display: none;
      }
    }
  }

  // open/close arrow should be aligned to the right
  .lemon-userMenu__menuOpener {
    margin-left: auto;
  }

  .lemon-userMenu__mainIcon {
    font-size: @icon-size-xxl;
  }
}

.lemon-userMenu__submenu {
  > .ant-menu {
    width: 100%;
    background-color: transparent;
    border: 0 none;

    // kill bottom menu border which is replaced with top margin
    > .ant-menu-submenu {
      border: 0 none;
    }
  }

  // make it look like menu item
  .ant-menu-submenu-title {
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65); // this is antd default menu color that has been overridden
  }

  .ant-menu-submenu-title:hover {
    color: @lemon-primary-color-1;
  }

  // don't know what this element is for but has no meaningfull content and causes overflow scroll of entire page
  .ant-menu-overflowed-submenu {
    display: none;
  }
}
