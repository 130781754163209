
.lemon-error__errorPlaceholderContainer {
  margin: 10% auto;
  width: 60%;
  text-align: center;
  opacity: 0.4;
}

.lemon-error__errorDesriptionContainer {
  text-align: left;
  max-width: 400px;
  margin: 0 auto;
}
