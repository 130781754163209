
// ----- configs
@drawer-width: calc(@lemon-layout-content-width - @panel-padding);
@drawer-height-min: 300px;
@drawer-height-max: 500px;


.lemon-tutoringRoomDrawer__container {
  position: absolute;
  width: @drawer-width;
  bottom: 0;
  left: 0;
  z-index: @stack-zindex-panel;

  border: @panel-border;
  background-color: @panel-light-bg;
  box-shadow: @lemon-shadow-heavy;

  // ----- adapt antd's collapse element to our needs
  &.ant-collapse.ant-collapse { // INTENTIONAL: double class so it gets more specific and overrides default styles
    .ant-collapse-item {
      display: flex;
      align-items: center;
      flex-direction: column;
      border: 0; // remove default border

      // ----- collapse activation element
      > .ant-collapse-header {
        margin-top: -30px; // push header to the top so entire panel ca go down to prevent it form taking entire space lef/right of header
        padding: @panel-padding/2 @panel-padding;
        width: auto;
        border: @panel-border;
        border-color: @lemon-primary-color-1;
        border-radius: @panel-border-radius;
        background-color: @lemon-primary-color-1;
        color: #FFF;

        > .ant-collapse-arrow {
          position: static;
          display: flex; // override arrow's default display
          transform: none;
          font-size: initial;
        }

      }

      // ----- collapse content
      > .ant-collapse-content {
        width: 100%; // flex items don't have full width (not block elements anymore) so we have stretch content

        .ant-collapse-content-box {
          padding: 0; // kill default padding and let us handle it so overflow looks nicer
        }
      }
    }
  }

}

.lemon-tutoringRoomDrawer__content {
  padding: 2*@panel-padding;
  min-height: @drawer-height-min;
  max-height: @drawer-height-max;
  overflow-y: auto;

  // adjust radius to follow main container
  border-top-left-radius: @panel-border-radius;
  border-top-right-radius: @panel-border-radius;
}

.lemon-whiteboardRoom__roomBackground {
  border-radius: @panel-border-radius; // room bg is protruding at the corners 
}

.lemon-tutoringRoomDrawer__divider.lemon-tutoringRoomDrawer__divider { // double class for greater selector specificity
  margin-top: 0;
}
