// ----- General

.lemon-tutorProfile__generalContainer {
  .ant-rate {
    line-height: 1; // remove extra space
  }

  .lemon-image__avatar {
    margin: 0 2 * @panel-padding;
  }

  .ant-col {
    @media @md-screen-lt {
      .text-center;
    }
  }
}

.lemon-tutorProfile__pastSessionsContainer {
  margin: @panel-padding / 2 0;
}

// ----- Calendar
.lemon-tutorProfile__timetable {
  position: relative;
  line-height: 0;
}

.lemon-tutorProfile__timetableHeader {
  width: 100%;
  height: 1.4em;
  display: flex;
}

.lemon-tutorProfile__timetableHeader__alignDummy {
  width: 5%;
  margin: auto;
}

.lemon-tutorProfile__timetableHeader__days {
  width: 90%;
  display: flex;
  justify-content: space-around;
  margin: 1em 0.7em 1em 0;
  font-size: @font-size-lg;
  color: @lemon-neutral-color-2;

  div {
    width: 10%;
    text-align: center;
  }
}

.lemon-tutorProfile__timetable__hour {
  display: flex;
  width: 100%;
  height: 1.5em;
  justify-content: space-between;
  font-size: @font-size-lg;
  color: @lemon-neutral-color-4;
}

.lemon-tutorProfile__timetable__divider {
  width: 90%;
  margin: 0.7em 0.7em 0.7em 0;
  min-width: 0%;
  background: @lemon-neutral-color-4;
  border-right-width: 0;
  border-bottom-width: 0;
  border-left-width: 0;
}

.lemon-tutorProfile__timetable__hour:nth-child(3n + 2) {
  color: @lemon-neutral-color-2;

  hr {
    border-color: @lemon-neutral-color-2;
  }
}

.lemon-tutorProfile__timetable__hourLabel {
  margin: auto;
  width: 9%;
  text-align: center;
}

.lemon-tutorProfile__timelineLabel {
  font-weight: bold;
  color: @lemon-neutral-color-1;
}

.lemon-tutorProfile__timetableEvents {
  display: flex;
  justify-content: space-around;
  position: absolute;
  top: 2.2em;
  left: 9%;
  right: 0.8em;
  bottom: 0.9em;
}

.lemon-tutorProfile__timetableEvents__events {
  height: 100%;
  width: 11%;
  background-color: rgba(@lemon-neutral-color-3, 0.25);
  text-align: center;
}

.lemon-tutorProfile__timetableEvents__events--event {
  position: absolute;
  display: flex;
  width: 11%;
  background-color: @lemon-primary-color-2;
  border-radius: 4px;

  span {
    margin: auto;
    color: white;
    font-size: @font-size-lg;
  }
}

// ----- Details

.lemon-tutorProfile__detailsContent {
  margin-top: 1em;
  margin-left: 1em;

  &.lemon-tutorProfile__detailsContent--list {
    margin-left: auto;
  }

  @media @md-screen-lt {
    margin: 0;
  }
}

// ----- Review

.lemon-tutorProfile__infoIcon {
  margin-left: @panel-padding;
}

.lemon-tutorProfile__reviewContent {
  .lemon-createBubble(@lemon-timeline-item-current-background, 'left');

  // experimental values that fit design
  width: 107%;
  margin-left: -7%;
}

.lemon-tutorProfile__profileLabel {
  .text-center;
}

.lemon-tutorProfile__reviewContainer {
  .ant-list-items > .ant-list-item.ant-list-item {
    // double class for more specificity
    &,
    &:last-child {
      border-bottom: 0 none;
    }
  }
}
