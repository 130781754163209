.lemon-welcome__container {
  align-items: center;
  justify-content: center;
  display: flex;

  width: 100%;
  flex: 1;
  z-index: @stack-zindex-element-1;
  overflow: hidden;
  background-image: url('../../img/welcome_splash.jpg');
  background-size: cover;

  > img {
    position: absolute;
    top: -30%; /* manually adjust for better header visibility */
    left: 0;
    width: 100%;
  }
}

.lemon-welcome__loginButton {
  background-color: white !important;
  width: 30%;
  max-width: 10em;
}

.lemon-welcome__content {
  max-width: 800px;
  padding: @padding-md;
  border-radius: @lemon-border-radius;
  color: @lemon-text-color-secondary;
  background-color: rgba(0, 0, 0, 0.45);
  text-align: center;
}

.lemon-welcome__contentTitle {
  color: @layout-header-text-color !important;
  .lemon-heading-1;

  @media @md-screen-lt {
    .lemon-heading-2;
  }
}

.lemon-welcome__contentNotice {
  color: @layout-header-text-color !important;
  font-size: @lemon-font-size-lg;
}

.lemon-welcome__appLogo {
  color: @lemon-primary-color-5 !important;
}
