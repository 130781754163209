
.lemon-createBubble(@bgColor, @direction, @textColor: #FFF, @fontSize: @lemon-font-size-base) {
  position: relative;

  border-radius: @panel-border-radius;
  padding: @panel-padding;
  word-wrap: break-word;
  color: @textColor;

  background-color: @bgColor;

  &:after when(@direction = 'left') {
    .__itemBubbleDirection--left(@fontSize, @bgColor);
  }

  &:after when(@direction = 'right') {
    .__itemBubbleDirection--right(@fontSize, @bgColor);
  }
}

// ----- private

/** Generate speach bubble arrow */

.__bubbleBase(@size) {
content: '';
position: absolute;
top: 0;
width: 0;
height: 0;
border: @size solid transparent;
border-top: 0;
margin-top: @size/2;
}

.__itemBubbleDirection--left(@size, @color) {
.__bubbleBase(@size);

left: 0;
border-right-color: @color;
border-left: 0;
margin-left: -@size;
}

.__itemBubbleDirection--right(@size, @color) {
.__bubbleBase(@size);

right: 0;
border-left-color: @color;
border-right: 0;
margin-right: -@size;
}

