@lemonVideoRoomHeight: 45%; // this is experimental value
@lemonVideoRoomPadding: @padding-md/2;

@videoTrackBgColor: @lemon-neutral-color-2;

.lemon-videoRoom__container {
  position: relative;
  border: @panel-border;
  border-radius: @panel-border-radius;
  height: @lemonVideoRoomHeight; // we must set some height to preven video from stretching entire container
  padding: @lemonVideoRoomPadding;
  background-color: @background-color-base;
}


// position video absolutetly above whiteboard
.lemon-tutoringRoom__layout--fullscreen {
  .lemon-videoRoom__container {
    position: absolute;
    top: @panel-padding;
    left: @panel-padding;
    bottom: @panel-padding;
    z-index: @stack-zindex-dialog;
    right: @panel-padding;
    // reset original height props because we're switching to absolute positioning
    height: unset;
    max-height: unset;
    box-shadow: @lemon-shadow-heavy;
  }
}

.lemon-videoRoom__localPreviewContainer {
  display: flex;
  width: 100%;
  height: 100%;
  z-index: @stack-zindex-panel;

  // align content
  flex-direction: column;
  justify-content: center;

  background-color: @videoTrackBgColor;

  .lemon-videoRoom__container--connected & {
    position: absolute;
    top: 0;
    right: 0;
    width: 20%; // experimental width values
    max-width: 150px; // experimental width values
    height: auto;
  }
}

.lemon-videoRoom__cameraDisabledWarning {
  text-align: center;
  color: white;
}

.lemon-videoRoom__roomErrorMessage {
  padding: @padding-lg;
  text-align: center;
  color: white;
}

.lemon-videoRoom__videoTrack {
  width: 100%;
  height: 100%;

  &--frontFacing {
    transform: rotateY(180deg);
  }
}


/* ----- participant info ------ */
.lemon-videoRoom__participantInfoContainer {
  position: relative;
  float: left; // this allows multiple participant videos to stack
  width: 100%;
  height: 100%;
  background-color: @videoTrackBgColor;
}

.lemon-videoRoom__participantInfoName {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
}

.lemon-videoRoom_participantInfoStateContrainer {
  position: absolute;
  top: 45%;
  right: 0;
  left: 0;
  text-align: center;
  color: red;
  padding: @panel-padding;
}

/* ----- participant strip */

.lemon-videoRoom__participantStripContainer {
  width: 100%;
  height: 100%;

  /* ----- Multi participant layouts ------ */

  &&--2participants {
    .lemon-videoRoom__participantInfoContainer {
      height: 50%;
    }
  }

  &&--3participants {
    // first participant entire row - half height
    .lemon-videoRoom__participantInfoContainer {
      max-height: 50%;
    }

    // other participants half row - half height
    .lemon-videoRoom__participantInfoContainer + .lemon-videoRoom__participantInfoContainer {
      width: 50%;
      max-height: 50%;
    }
  }

  &&--4participants {
    // all participants half row - half height
    .lemon-videoRoom__participantInfoContainer {
      width: 50%;
      max-height: 50%;
    }
  }
}

.lemon-videoRoom__localControlBarContainer {
  position: absolute;
  right: @lemonVideoRoomPadding;
  bottom: @lemonVideoRoomPadding;
  left: @lemonVideoRoomPadding;
  padding: @padding-md;
}



// -------------------------------------------- OLD

.lemon-videoRoom__fullVideoControl {
  position: absolute;
  top: @panel-padding;
  right: @panel-padding;

  cursor: pointer;
}

.lemon-videoRoom__muteControl {
  position: absolute;
  bottom: @panel-padding;
  left: @panel-padding;
}

.lemon-videoRoom__mediaContainer {
  video {
    width: 100%;
    max-height: 100%;
  }

  &.lemon-videoRoom__mediaContainer--localMedia {
    transform: rotateY(180deg); // we want to see local video from a selfie perspective and not from other participant's perspective
  }
}

.lemon-videoRoom__mainParticipant {
  height: 100%;
}

.lemon-videoRoom__participantStripe {
  position: absolute;
  bottom: @panel-padding;
  display: flex;
  right: @panel-padding;
  width: 25%;
  align-items: flex-end;
}

.lemon-videoRoom__mediaPlaceholder {
  display: flex;
  height: 100%;

  align-items: center;
  justify-content: center;

  background-color: @panel-bg;

  text-align: center;
}
