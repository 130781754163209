// experimental sizes - if it doesn't fit any more, measure it again
@lemon-tutoringRoom-sessionInfo-height: 60px;
@lemon-tutoringRoom-toolbar-height: 48px;

.lemon-whiteboardRoom__roomBackground {
  background: url('../../img/whiteboard-bg.svg') #f9f9f9 repeat;
  background-size: 40px 40px;
  width: 100%;
  height: 100%;
}

// ---------- Awwapp overrides
// TODO: we should probably download CSS fro awwapp just as we did with JS and HTML?!

#tutoring_room-whiteboard_container {
  .panel--noSpace;

  // whiteroom has minimal container only for action buttons
  .lemon-tutoringRoom__roomContainer & {
    margin-top: @lemon-tutoringRoom-sessionInfo-height + 2 * @panel-padding;
    margin-right: calc(@panel-padding + @lemon-layout-sider-width);
  }

  &.aww .toolbar__box--middle-left {
    top: @panel-padding;
    bottom: @panel-padding;
  }

  .lemon-tutoringRoom__roomContainer &.aww .toolbar__box--middle-left {
    top: @panel-padding; // session info container height + padding
  }

  &.aww .toolbar__box--middle-right {
    top: auto;
    bottom: @lemon-tutoringRoom-toolbar-height + 2 * @panel-padding; // whiteboard pager height +
    right: @panel-padding;

    // whiteroom has minimal container only for action buttons
    .lemon-tutoringRoom__whiteroomContainer & {
      right: @panel-padding;
    }
  }

  &.aww .toolbar__box--bottom-right {
    // for some reason pointer events are disabled on this toolbar?!
    pointer-events: initial;
    right: @panel-padding;

    // whiteroom has minimal container only for action buttons
    .lemon-tutoringRoom__whiteroomContainer & {
      right: @panel-padding;
    }
  }

  /*
  &.aww .toolbar--vertical {
    // max-height: 100%;
  }
  */
  &.aww .tools__mini {
    // add missing border
    border: 1px solid #ddd;
    border-radius: 8px;
  }

  &.aww .toolbar__box--bottom-right {
    flex-direction: row;
  }

  &.aww .tools__item--button:hover[class*='has--tooltip']::after {
    width: auto;
    padding: 0 0.5em;
  }

  #spinnable {
    background-color: rgba(0, 0, 0, 0.4) !important;
    z-index: @stack-zindex-dialog !important;
  }
}

.toolbar--bottom {
  top: auto;
  bottom: -12px;
}
