.lemon-loginMenuContainer {
  // better child element arrangement
  display: flex;
  justify-content: flex-end;

  // reduce padding to save space
  .ant-menu-submenu-title {
    padding-right: 0;
  }
}

.lemon-loginMenu__loginMenu {
  margin-left: auto; // push container to the left

  *,
  *:hover {
    color: @layout-header-text-color;
  }
}

.lemon-loginMenu__button {
  background-color: white !important;

  span {
    // added to try to override login menu's whildcard selectors
    color: @lemon-primary-color-1;
  }
}
