.lemon-sessionView__header {
  line-height: @avatar-size;
  padding: @panel-padding;
}

.lemon-sessionView__participantContainer {
  text-align: right;
}

.lemon-sessionView__participantName {
  display: inline-block;
  font-size: @lemon-font-size-lg;
  color: @lemon-heading-color;
  text-align: right;
}

.lemon-sessionView__participantAvatar {
  display: inline-block;
  padding: 0 24px;
}

.lemon-sessionView__sessionStartContainer {
  border-bottom: @panel-border;
  margin-bottom: @panel-padding;
}

.lemon-sessionView__sessionStartDate {
  display: inline-block;
  padding: @panel-padding / 2 @panel-padding;
  font-size: 2em;
  line-height: 1; // override collapse panel's header line height
  color: @lemon-heading-color;
}

.lemon-sessionView__sessionStartTime {
  display: inline-block;
  font-size: @lemon-font-size-lg;
  color: @lemon-heading-color;
  cursor: pointer;
}

.lemon-sessionView__educationArea {
  display: flex;
  justify-content: start;
}

.lemon-sessionView__usersMenu {
  padding-top: @panel-padding / 4;
}

.lemon-sessionView__sessionActionsContainer {
  text-align: right;
}

.lemon-sessionView__sessionFormControlContainer {
  padding: @panel-padding;
}

.lemon-sessionView__sessionInfoItemContainer {
  label {
    font-weight: bold;
  }
}

.lemon-sessionView__sessionStatusContainer {
  display: inline-block;
  margin: 0 @panel-padding;
}

.lemon-sessionView__sessionDescription {
  min-height: 3em;
  max-height: 10em;
  padding: @panel-padding / 2 @panel-padding;
  overflow-y: auto; // handle large text

  white-space: pre-wrap;
  line-height: 1.5;
}

.lemon-sessionPage__actions-openWhiteroomLink {
  margin-right: 5px;
  display: inline;
}
