.lemon-login__loginForm {
  margin: 10% auto;
  min-width: @lemon-password-form-min-width-base;
  padding: @padding-lg;
  width: @lemon-password-form-width-base;

  .anticon {
    color: rgba(0, 0, 0, 0.25);
  }

  @media @md-screen-lt {
    min-width: @lemon-password-form-min-width-small;
    width: @lemon-password-form-width-small;
  }
}
