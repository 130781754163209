.lemon-adminLayout__header {
  &:extend(.lemon-appLayout__header);

  padding: 0;
}

.lemon-adminLayout__content {
  &:extend(.lemon-appLayout__content);
}

.lemon-userActivityList__filterForm {

  .ant-form-item{
    width: 100%;
  }
  .ant-form-item-control-wrapper{
    width: 60%;
  }
}

.lemon-userActivityList__filterForm-submitButton {
  text-align: right;
}