
.lemon-userInfoView__container {
  line-height: 1.5;

  .lemon-userInfoView__avatar {
    margin-right: @panel-padding;
  }

  .lemon-userInfo__title {
    text-transform: uppercase;
    font-weight: 600;
  }

  .lemon-userInfo__description {
  }
}
