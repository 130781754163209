

// internal class, transforms some tags into pills
.__pill {
  border-radius: @padding-md;
}

.lemon-tag__tag {

  .anticon-close {
    color: inherit; // make sure close button inherits tag's color
  }

  // ----- sizes

  &--large {
    font-size: @lemon-font-size-lg;
    line-height: 1.5;
  }

  // ----- tags

  &--fileTag {
    background-color: @lemon-primary-color-1;
    border: 1px solid @lemon-primary-color-1;
    color: #FFF;

    &:hover {
      border-color: @lemon-primary-color-4;
      background-color: @lemon-primary-color-4;
      color: @lemon-primary-color-1;
    }
  }

  &--fileNewTag {
    background-color: #FFF;
    border: 1px dashed @lemon-neutral-color-3;
    color: @lemon-neutral-color-3;

    &:hover {
      border-color: @lemon-primary-color-1;
      background-color: @lemon-primary-color-4;
      color: @lemon-primary-color-1;
    }
  }

  &--educationArea {
    background-color: @lemon-primary-color-2;
    border: 1px solid @lemon-primary-color-2;
    color: #FFF;
  }

  // ----- pills
  
  &--scheduledSession {
    .__pill;

    background-color: @lemon-primary-color-5;
    border: 1px solid @lemon-primary-color-5;
    color: @lemon-primary-color-1;
  }

  &--confirmedSession {
    .__pill;

    background-color: @lemon-primary-color-4;
    border: 1px solid @lemon-primary-color-4;
    color: @lemon-primary-color-1;
  }

  &--heldSession {
    .__pill;

    background-color: @lemon-primary-color-1;
    border: 1px solid @lemon-primary-color-1;
    color: #FFF;
  }

  &--canceledSession {
    .__pill;

    background-color: @lemon-neutral-color-3;
    border: 1px solid @lemon-neutral-color-3;
    color: inherit;
  }

  &--info {
    .__pill;

    background-color: @lemon-primary-color-3;
    border: 1px solid @lemon-primary-color-3;
    color: #FFF;
  }

}
