.lemon-sessionList__container {
  // kill default panel's bg/border
  background: none;
  border: 0 none;
}

.lemon-sessionList__sessionPanel {
  .ant-collapse-arrow {
    left: initial !important;
    right: @padding-lg;
    font-size: @icon-size-xl !important;
  }

  &.ant-collapse-item {
    > .ant-collapse-header {
      padding: 0; // override default padding
    }

    > .ant-collapse-content.ant-collapse-content-active {
      margin-top: 12px; // prevent content from sticking to close to header once panel is opened
    }
  }
}

.lemon-sessionList__fileCardList {
  .ant-card-body {
    padding: 1px;
  }

  .ant-card-meta-title {
    font-size: inherit;
  }

  span.ant-typography-ellipsis {
    display: block;
  }
}
