.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.float-right {
  float: right;
}

.float-left {
  float: right;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.panel {
  border: @panel-light-border;
  border-radius: @panel-border-radius;
  margin: @panel-padding @panel-padding 0 0;
  padding: @panel-padding;
  background-color: @panel-light-bg;
  box-shadow: @lemon-shadow-light;

  @media @md-screen-lt {
    padding: @padding-md;
  }
}

.panel--inverted {
  .panel;

  border: @panel-border;
  background-color: @panel-bg;
}

.panel--noSpace {
  .panel;

  margin: 0;
  padding: 0;
}

.panel--noPadding {
  .panel;

  padding: 0;
}

// indent = only margins and padding from panel
.indent {
  padding: @panel-padding;

  &:last-child {
    margin-bottom: @panel-padding;
  }
}

.hidden {
  display: none !important;
}

.lemon-appLayout__layout--welcomeSplash {
  .lemon-appLayout__header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: @stack-zindex-panel;
    background-color: transparent;
  }

  // reset @media effects when having splash layout
  @media @xl-screen-gte {
    .lemon-appLayout__content {
      width: auto;
      margin: 0;
      background-color: transparent;
    }
  }
}

// Function that gives scrollable box indicators (shadow) if there is still more to scroll (up or down).
.lemon-scrollbox-vertical(@bg, @shadow) {
  height: 100%;
  overflow: auto;
  background: linear-gradient(@bg 30%, fade(@shadow, 0)), linear-gradient(fade(@shadow, 0), @bg 70%) 0 100%, radial-gradient(farthest-side at 50% 0, @shadow, rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, @shadow, rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;
  background-color: @bg;
}

// ----- flex containers

.lemon-common__flexContainer {
  display: flex;

  // horizontal is the default

  &--vertical {
    flex-direction: column;
  }
}

.lemon-common__flexItem {
  &--full {
    flex: 1;
  }
}

// ----- Lists

ul.lemon-common__list {
  list-style: none;

  li::before {
    content: '\2022'; //"•";
    float: left;
    width: 1em; // make space for bullet
    margin-left: -1em; // move bullete into created spaces
    font-size: @lemon-font-size-lg;
    line-height: 1em;
    color: @lemon-primary-color-2;
  }
}

/**
  used for forms when no space for validation messages needed
  or want the form looks more compact
*/
.lemon-common__formItems--noMargin {
  .ant-form-item {
    margin: 0;
  }
}
