
.lemon-timeline__messageSendForm {
  padding: @panel-padding;
  display: flex;
  flex-direction: row;
  border-top: @panel-border;
  
  background-color: @lemon-neutral-color-6;

  .ant-form-item {
    margin: 0;
  }
}

.lemon-timeline__messageSendTextboxContainer {
  width: 100%;
}

