
.lemon-multisessionView-sessionList {
  .ant-collapse .ant-collapse-item .ant-collapse-header .anticon {
    left: initial;
    right: 16px;
  }
  
  .ant-collapse .ant-collapse-item .ant-collapse-header {
    padding: 12px 40px 12px 12px;
  }
}

.lemon-multisessionView__header {
  padding: 0;
  display: flex;

  .lemon-multisessionView__userInfoContainer {
    width: 65%;
    padding: 2*@padding-sm @padding-sm @padding-sm;
  }

  .lemon-multisessionView__createSessionButton {
    width: 35%;
    padding: 2*@padding-sm @padding-sm @padding-sm;
  }
}


.lemon-multisessionView__dateNavigationContainer {
  height: @user-info-view-height;

  // rest containers font size
  line-height: 1;
  font-size: @lemon-font-size-lg;

  // TODO: this could become a separate componente (eg. PrevNextPicker :-))
  .lemon-multisessionView__dateNavigation {
    margin-left: auto; // push container to the left
    color: @heading-color;

    .lemon-multisessionView__previousDate,
    .lemon-multisessionView__nextDate {
      cursor: pointer;
      color: @heading-color;
    }

    .lemon-multisessionView__previousDate {
      margin: 0 @padding-sm/2 0 @padding-sm;
    }
    .lemon-multisessionView__nextDate {
      margin: 0 @padding-sm 0 @padding-sm/2;
    }
  }
}

.__containerHeight {
  height: 100%;
  min-height: 400px;
  max-height: 600px;
}

.lemon-multisessionView__sider {
  background-color: transparent;

  // affix has height 0 because timeline is absolutely positioned, so we have to keep it's height
  .lemon-multisessionView__affix {
    margin-top: @panel-padding;

    &, & > DIV {
      .__containerHeight;
    }
  }

  // outer container for keeping container's h/w
  .lemon-timeline__container {
    .__containerHeight;

    border-radius: @panel-border-radius;
    background-color: @panel-light-bg;
  }
}
